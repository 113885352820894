import {FC, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {FrontendUserId, HoobiizUserGroupId} from '@shared/dynamo_model';

import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {
  loadHoobiizUserGroupUsers,
  useHoobiizUserGroup,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {AdminUserAndGroupUserLine} from '@src/components/admin/user_and_group/admin_user_and_group_user_line';
import {Colors} from '@src/components/core/theme_base';

interface AdminUserAndGroupUsersProps {
  groupId: HoobiizUserGroupId;
  isUserHighlighted?: (id: FrontendUserId) => boolean;
  shouldAutoLoadUsers: boolean;
}

export const AdminUserAndGroupUsers: FC<AdminUserAndGroupUsersProps> = props => {
  const {groupId, isUserHighlighted, shouldAutoLoadUsers} = props;
  const group = useHoobiizUserGroup({groupId});

  // Load the next page of users
  const fetchNextPage = useCallback(
    async () => loadHoobiizUserGroupUsers({parentGroupId: groupId, isInitialLoad: false}),
    [groupId]
  );

  // Initial load
  useEffect(() => {
    if (shouldAutoLoadUsers) {
      loadHoobiizUserGroupUsers({parentGroupId: groupId, isInitialLoad: true}).catch(() => {});
    }
  }, [groupId, shouldAutoLoadUsers]);

  // Handle shouldAutoLoadUsers
  const [forceShowUsers, setForceShowUsers] = useState(shouldAutoLoadUsers);
  const enableForceShowUsers = useCallback(async () => {
    setForceShowUsers(true);
    await fetchNextPage();
  }, [fetchNextPage]);

  if (!group?.users) {
    return <Wrapper>Chargement des utilisateurs...</Wrapper>;
  }

  const {item, users} = group;
  const {groupDirectUserCount} = item;

  if (groupDirectUserCount === 0) {
    return EmptyFragment;
  }

  if (!forceShowUsers) {
    return (
      <AddButton onClickAsync={enableForceShowUsers}>
        <SvgIcon width={14} height={16} color={Colors.DarkGold} name="TwoUsers" />
        <div>Afficher les utilisateurs</div>
      </AddButton>
    );
  }

  return (
    <Wrapper>
      <UserLines>
        {users.items?.map(user => (
          <AdminUserAndGroupUserLine
            key={user.userId}
            userId={user.userId}
            isUserHighlighted={isUserHighlighted}
          />
        ))}
      </UserLines>
      {users.nextPageToken !== undefined ? (
        <Footer>
          <Sep />
          <MoreUserMessage key="message">
            {`${users.items?.length.toLocaleString()} / ${groupDirectUserCount.toLocaleString()} utilisateurs affichés`}
          </MoreUserMessage>
          <ButtonAsLink key="button" onClickAsync={fetchNextPage}>
            Charger plus d'utilisateurs
          </ButtonAsLink>
        </Footer>
      ) : (
        EmptyFragment
      )}
    </Wrapper>
  );
};

AdminUserAndGroupUsers.displayName = 'AdminUserAndGroupUsers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const UserLines = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 8px;
`;

const Sep = styled.div`
  width: 32px;
  height: 5px;
  margin-bottom: 4px;
  background-color: #e2e2e2;
`;

const MoreUserMessage = styled.div`
  font-style: italic;
  color: #888;
`;

const AddButton = styled(ButtonAsLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
  margin-left: 8px;
`;
