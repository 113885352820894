import {useCallback, useEffect, useMemo} from 'react';

import {
  HoobiizApi,
  HoobiizGroupItem,
  HoobiizUserItem,
} from '@shared/api/definitions/public_api/hoobiiz_api';
import {FrontendUserId, HoobiizUserGroupId, HoobiizUserProfile} from '@shared/dynamo_model';
import {uniq} from '@shared/lib/array_utils';
import {removeUndefined} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {createMapStore} from '@shared-frontend/lib/map_data_store';
import {notifyError} from '@shared-frontend/lib/notification';
import {useMemoCompare} from '@shared-frontend/lib/use_memo_compare';

import {
  getHoobiizUserGroupModalPath,
  getHoobiizUserGroupUserModalPath,
} from '@src/components/admin/user_and_group/admin_user_and_group_paths';

interface HoobiizUserGroupStoreItem {
  item: HoobiizGroupItem;
  subGroups: {
    isFetching?: boolean;
    items?: HoobiizUserGroupId[];
    nextPageToken?: string;
  };
  users: {
    isFetching?: boolean;
    hasLoadedOnce?: boolean;
    items?: HoobiizUserItem[];
    nextPageToken?: string;
  };
}

const adminUserGroupStore = createMapStore<HoobiizUserGroupId, HoobiizUserGroupStoreItem>();
export const getAdminUserGroup = adminUserGroupStore.getData;
const getAllAdminUserGroup = adminUserGroupStore.getAllData;
const useAllAdminUserGroup = adminUserGroupStore.useAllData;
const setAdminUserGroup = adminUserGroupStore.setData;
const deleteAdminUserGroup = adminUserGroupStore.unsetData;
const batchSetAdminUserGroup = adminUserGroupStore.batchSetData;

export function useHoobiizUserGroup(opts: {
  groupId: HoobiizUserGroupId;
}): HoobiizUserGroupStoreItem | undefined {
  const {groupId} = opts;
  const current = adminUserGroupStore.useData(groupId);
  useEffect(() => {
    if (!current) {
      // Trigger a load if not available
      getOrFetchHoobiizUserGroup({groupId}).catch(() => {});
    }
  }, [current, groupId]);
  return current;
}

const userGroupFetchAwaiters = new Map<
  HoobiizUserGroupId,
  {resolve: (group: HoobiizUserGroupStoreItem) => void; reject: (err: unknown) => void}[]
>();
async function getOrFetchHoobiizUserGroup(opts: {
  groupId: HoobiizUserGroupId;
}): Promise<HoobiizUserGroupStoreItem> {
  return new Promise<HoobiizUserGroupStoreItem>((resolve, reject) => {
    // Check if already in the store
    const {groupId} = opts;
    const groupStoreItem = getAdminUserGroup(groupId);
    if (groupStoreItem) {
      resolve(groupStoreItem);
      return;
    }

    // Not in the store, register as an awaiter
    let awaiters = userGroupFetchAwaiters.get(groupId);
    if (!awaiters) {
      awaiters = [];
      userGroupFetchAwaiters.set(groupId, awaiters);
    }
    awaiters.push({resolve, reject});
    if (awaiters.length > 1) {
      return; // someone is already fetching
    }

    // Fetch the group data
    apiCall(HoobiizApi, '/admin/get-user-group', {groupId})
      .then(({item}) => {
        const groupStoreItem = {item, subGroups: {}, users: {}};
        setAdminUserGroup(groupId, groupStoreItem);
        for (const {resolve} of userGroupFetchAwaiters.get(groupId) ?? []) {
          resolve(groupStoreItem);
        }
      })
      .catch(err => {
        for (const {reject} of userGroupFetchAwaiters.get(groupId) ?? []) {
          reject(err);
        }
      });
  });
}

export function useHoobiizUserGroupUser(opts: {
  userId: FrontendUserId;
}): {user: HoobiizUserItem; group: HoobiizGroupItem} | undefined {
  const {userId} = opts;
  const allGroups = useAllAdminUserGroup();
  return useMemo(() => {
    const res = getOrFetchUser({userId});
    return 'then' in res ? undefined : res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups, userId]);
}

const userGroupUserFetchAwaiters = new Map<
  FrontendUserId,
  {
    resolve: (data: {user: HoobiizUserItem; group: HoobiizGroupItem}) => void;
    reject: (err: unknown) => void;
  }[]
>();
interface FetchUserResult {
  user: HoobiizUserItem;
  group: HoobiizGroupItem;
}
function getOrFetchUser(opts: {
  userId: FrontendUserId;
}): FetchUserResult | Promise<FetchUserResult> {
  // Check if already in the store
  const {userId} = opts;
  const allGroups = getAllAdminUserGroup();
  for (const group of allGroups.values()) {
    const user = group.users.items?.find(u => u.userId === userId);
    if (user) {
      return {user, group: group.item};
    }
  }

  return new Promise<FetchUserResult>((resolve, reject) => {
    // Not in the store, register as an awaiter
    let awaiters = userGroupUserFetchAwaiters.get(userId);
    if (!awaiters) {
      awaiters = [];
      userGroupUserFetchAwaiters.set(userId, awaiters);
    }
    awaiters.push({resolve, reject});
    if (awaiters.length > 1) {
      return; // someone is already fetching
    }

    // Fetch the user and group
    apiCall(HoobiizApi, '/admin/get-user', {userId})
      .then(({user, groups}) => {
        batchSetAdminUserGroup([
          ...groups.map(g => {
            const current = getAdminUserGroup(g.groupId);
            const subGroups = current?.subGroups ?? {};
            const users = {...current?.users, items: [...(current?.users.items ?? []), user]};
            return {
              key: g.groupId,
              value: {item: g, subGroups, users},
            };
          }),
        ]);
        const group = groups.find(g => g.groupId === user.parentGroupId);
        if (!group) {
          throw new Error('User group not found');
        }
        const result = {user, group};
        for (const {resolve} of userGroupUserFetchAwaiters.get(userId) ?? []) {
          resolve(result);
        }
      })
      .catch(err => {
        for (const {reject} of userGroupUserFetchAwaiters.get(userId) ?? []) {
          reject(err);
        }
      });
  });
}

export async function loadHoobiizUserGroupSubgroups(opts: {
  parentGroupId: HoobiizUserGroupId;
  isInitialLoad: boolean;
}): Promise<void> {
  const {parentGroupId, isInitialLoad} = opts;

  // Ensure the group exists in the store
  let current = await getOrFetchHoobiizUserGroup({groupId: parentGroupId});

  // Subgroup fetching
  const shouldFetchSubGroups =
    !current.subGroups.isFetching &&
    (current.subGroups.items === undefined ||
      (!isInitialLoad && current.subGroups.nextPageToken !== undefined));
  if (shouldFetchSubGroups) {
    // Update to indicate we are fetching
    current = {...current, subGroups: {...current.subGroups, isFetching: true}};
    setAdminUserGroup(parentGroupId, current);
    // Start the API call
    await apiCall(HoobiizApi, '/admin/list-sub-groups-in-group', {
      groupId: parentGroupId,
      nextPageToken: current.subGroups.nextPageToken,
    })
      .then(res => {
        // Get the store value again since it might have changed since then
        const newCurrent = getAdminUserGroup(parentGroupId);
        if (!newCurrent) {
          return;
        }
        // Update with the new data
        batchSetAdminUserGroup(
          res.items.map(group => ({
            key: group.groupId,
            value: {item: group, subGroups: {}, users: {}},
          }))
        );
        setAdminUserGroup(parentGroupId, {
          ...newCurrent,
          subGroups: {
            isFetching: false,
            items: uniq([
              ...(newCurrent.subGroups.items ?? []),
              ...res.items.map(group => group.groupId),
            ]),
            nextPageToken: res.nextPageToken,
          },
        });
      })
      .catch((err: unknown) => {
        // Get the store value again since it might have changed since then
        const newCurrent = getAdminUserGroup(parentGroupId);
        if (!newCurrent) {
          return;
        }
        // Update to indicate we are not fetching anymore
        setAdminUserGroup(parentGroupId, {
          ...newCurrent,
          subGroups: {...newCurrent.subGroups, isFetching: false},
        });
        notifyError(err, {message: `Échec du chargement du groupe "${current.item.groupLabel}"`});
      });
  }
}

export async function loadHoobiizUserGroupUsers(opts: {
  parentGroupId: HoobiizUserGroupId;
  isInitialLoad: boolean;
}): Promise<void> {
  const {parentGroupId, isInitialLoad} = opts;

  // Ensure the group exists in the store
  let current = await getOrFetchHoobiizUserGroup({groupId: parentGroupId});

  // User fetching
  const shouldFetchUsers =
    !current.users.isFetching &&
    (!current.users.hasLoadedOnce || (!isInitialLoad && current.users.nextPageToken !== undefined));
  if (shouldFetchUsers) {
    // Update to indicate we are fetching
    current = {...current, users: {...current.users, isFetching: true}};
    setAdminUserGroup(parentGroupId, current);
    // Start the API call
    await apiCall(HoobiizApi, '/admin/list-users-in-group', {
      groupId: parentGroupId,
      nextPageToken: current.users.nextPageToken,
      limit:
        current.users.items === undefined
          ? 10
          : // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            current.users.items.length < 90
            ? 100 - current.users.items.length
            : undefined,
    })
      .then(res => {
        // Get the store value again since it might have changed since then
        const newCurrent = getAdminUserGroup(parentGroupId);
        if (!newCurrent) {
          return;
        }
        // Update with the new data
        setAdminUserGroup(parentGroupId, {
          ...newCurrent,
          users: {
            items: uniq([...(newCurrent.users.items ?? []), ...res.items], u => u.userId),
            nextPageToken: res.nextPageToken,
            isFetching: false,
            hasLoadedOnce: true,
          },
        });
      })
      .catch((err: unknown) => {
        // Get the store value again since it might have changed since then
        const newCurrent = getAdminUserGroup(parentGroupId);
        if (!newCurrent) {
          return;
        }
        // Update to indicate we are not fetching anymore
        setAdminUserGroup(parentGroupId, {
          ...newCurrent,
          users: {...newCurrent.users, isFetching: false},
        });
        notifyError(err, {message: `Échec du chargement du groupe "${current.item.groupLabel}"`});
      });
  }
}

export async function createHoobiizUserGroup(opts: {
  groupId: HoobiizUserGroupId;
  label: string;
}): Promise<void> {
  const {groupId, label} = opts;
  const newGroup = await apiCall(HoobiizApi, '/admin/create-user-group', {
    parentGroupId: groupId,
    groupLabel: label,
  });
  const currentParentGroup = await getOrFetchHoobiizUserGroup({groupId});
  batchSetAdminUserGroup([
    {
      key: newGroup.groupId,
      value: {
        item: newGroup,
        subGroups: {},
        users: {},
      },
    },
    {
      key: groupId,
      value: {
        ...currentParentGroup,
        subGroups: {
          ...currentParentGroup.subGroups,
          items: [...(currentParentGroup.subGroups.items ?? []), newGroup.groupId],
        },
      },
    },
  ]);
}

export async function deleteHoobiizUserGroup(opts: {groupId: HoobiizUserGroupId}): Promise<void> {
  const {groupId} = opts;
  await apiCall(HoobiizApi, '/admin/delete-user-group', {groupId});
  showSuccess(`Groupe supprimé`);
  const parentGroupId = getAdminUserGroup(groupId)?.item.parentGroupId;
  deleteAdminUserGroup(groupId);
  if (parentGroupId === undefined) {
    return;
  }
  const currentParentGroup = getAdminUserGroup(parentGroupId);
  if (!currentParentGroup) {
    return;
  }
  setAdminUserGroup(parentGroupId, {
    ...currentParentGroup,
    subGroups: {
      ...currentParentGroup.subGroups,
      items: (currentParentGroup.subGroups.items ?? []).filter(id => id !== groupId),
    },
  });
}

export async function updateHoobiizUserGroup(opts: {
  groupId: HoobiizUserGroupId;
  newLabel?: string;
  newProfile?: HoobiizUserProfile;
}): Promise<void> {
  const {groupId, newLabel, newProfile} = opts;
  const updatedGroup = await apiCall(HoobiizApi, '/admin/update-user-group', {
    groupId,
    groupLabel: newLabel,
    groupProfile: newProfile,
  });
  showSuccess('Groupe mis à jour');
  const currentGroup = getAdminUserGroup(groupId);
  setAdminUserGroup(groupId, {...(currentGroup ?? {subGroups: {}, users: {}}), item: updatedGroup});
}

export function hoobiizUserGroupHierarchyToGroupIds(
  hierarchy: HoobiizUserGroupHierarchy
): HoobiizUserGroupId[] {
  return removeUndefined(hierarchy.map(g => ('group' in g ? g.group.groupId : undefined)));
}

export async function moveHoobiizUserToGroup(opts: {
  userId: FrontendUserId;
  previousParentGroupId: HoobiizUserGroupId;
  newParentGroupId: HoobiizUserGroupId;
}): Promise<void> {
  const {userId, previousParentGroupId, newParentGroupId} = opts;

  // Perform the move in the backend
  const newGroups = getHoobiizUserGroupHierarchy({groupId: newParentGroupId});
  await apiCall(HoobiizApi, '/admin/move-user-to-group', {
    userId,
    groupIds: hoobiizUserGroupHierarchyToGroupIds(newGroups),
  });

  // Get the latest value we have for the user
  const userGroupStoreItem = getAdminUserGroup(previousParentGroupId);
  const user = userGroupStoreItem?.users.items?.find(u => u.userId === userId);

  // Identify all the impacted groups
  const previousGroups = getHoobiizUserGroupHierarchy({groupId: previousParentGroupId});
  const previousGroupsIds = hoobiizUserGroupHierarchyToGroupIds(previousGroups);
  const previousGroupsIdsLookup = new Set(previousGroupsIds);
  const newGroupIds = hoobiizUserGroupHierarchyToGroupIds(newGroups);
  const newGroupIdsLookup = new Set(newGroupIds);
  const allGroups = uniq([...previousGroupsIds, ...newGroupIds]);

  // Update user parent group id
  if (user) {
    user.parentGroupId = previousParentGroupId;
  }

  // Prepare the store updates
  const updates = removeUndefined(
    allGroups.map(id => {
      const current = getAdminUserGroup(id);
      if (!current) {
        return undefined;
      }

      // User count update
      let nestedUserCountDelta = 0;
      let directUserCountDelta = 0;
      if (previousGroupsIdsLookup.has(id) && !newGroupIdsLookup.has(id)) {
        nestedUserCountDelta--;
      } else if (!previousGroupsIdsLookup.has(id) && newGroupIdsLookup.has(id)) {
        nestedUserCountDelta++;
      }
      if (previousParentGroupId === id && newParentGroupId !== id) {
        directUserCountDelta--;
      } else if (previousParentGroupId !== id && newParentGroupId === id) {
        directUserCountDelta++;
      }

      // Users update
      let newUsers = current.users.items ?? [];
      if (previousParentGroupId === id && newParentGroupId !== id) {
        newUsers = newUsers.filter(u => u.userId !== userId);
      } else if (previousParentGroupId !== id && newParentGroupId === id && user) {
        newUsers = [...newUsers, user].sort((u1, u2) => u1.userEmail.localeCompare(u2.userEmail));
      }

      return {
        key: id,
        value: {
          ...current,
          item: {
            ...current.item,
            groupNestedUserCount: current.item.groupNestedUserCount + nestedUserCountDelta,
            groupDirectUserCount: current.item.groupDirectUserCount + directUserCountDelta,
          },
          users: {
            ...current.users,
            items: newUsers,
          },
        },
      };
    })
  );
  batchSetAdminUserGroup(updates);
}

type HoobiizUserGroupHierarchy = (({group: HoobiizGroupItem} | {user: HoobiizUserItem}) & {
  modalPath: string;
})[];

export function getHoobiizUserGroupHierarchy(
  opts:
    | {
        groupId: HoobiizUserGroupId;
      }
    | {userId: FrontendUserId}
): HoobiizUserGroupHierarchy {
  if ('userId' in opts) {
    const res = getOrFetchUser({userId: opts.userId});
    // Promise returned, we don't have the data yet
    if ('then' in res) {
      return [];
    }
    const hierarchy = getHoobiizUserGroupHierarchy({groupId: res.group.groupId});
    const modalPath = getHoobiizUserGroupUserModalPath({
      hierarchy: hoobiizUserGroupHierarchyToGroupIds(hierarchy),
      userId: opts.userId,
    });
    return [...hierarchy, {user: res.user, modalPath}];
  }

  const {groupId} = opts;
  // Get the hierarchy of groups
  let currentGroupId = groupId;
  const hierarchyItems: HoobiizGroupItem[] = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const group = getAdminUserGroup(currentGroupId)?.item;
    if (!group) {
      break;
    }
    hierarchyItems.unshift(group);
    currentGroupId = group.parentGroupId;
  }

  // Build the modal path for each items
  const res: {group: HoobiizGroupItem; modalPath: string}[] = [];
  const currentHierarchyIds: HoobiizUserGroupId[] = [];
  for (const group of hierarchyItems) {
    currentHierarchyIds.push(group.groupId);
    res.push({group, modalPath: getHoobiizUserGroupModalPath({hierarchy: currentHierarchyIds})});
  }

  return res;
}

export function useHoobiizUserGroupHierarchy(
  opts:
    | {
        groupId: HoobiizUserGroupId;
      }
    | {userId: FrontendUserId}
): HoobiizUserGroupHierarchy {
  const allGroups = useAllAdminUserGroup();
  const memoOpts = useMemoCompare(() => opts, [opts]);
  const getHierarchy = useCallback(() => {
    return getHoobiizUserGroupHierarchy(memoOpts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups, memoOpts]);
  return useMemoCompare(getHierarchy, [allGroups]);
}
