import {
  ExpertTicketRealPriceResponse,
  ExpertTicketReservationResponse,
  ExpertTicketTransactionResponse,
} from '@shared/api/definitions/expert_ticket_api';
import {FrontendSharedApi} from '@shared/api/definitions/frontend_shared_api';
import {PlaceGeometry} from '@shared/api/definitions/google_places_api';
import {
  HoobiizExpertTicketFixedStock,
  HoobiizExpertTicketFlexibleStock,
  SearchApi as SearchApiValue,
} from '@shared/api/definitions/search_api';
import {ApiMetadata} from '@shared/api/model';
import {ApiDef} from '@shared/api/registry';
import {
  CurrencyAmount,
  EmailString,
  ExpertTicketHoobiizCartItemItem,
  ExpertTicketHoobiizCartItemPayload,
  FrontendUserDataContentHoobiiz,
  FrontendUserId,
  FrontendUserItem,
  HoobiizActivityId,
  HoobiizActivityItem,
  HoobiizCartItemId,
  HoobiizCat1Id,
  HoobiizCat1Item,
  HoobiizCat2Id,
  HoobiizCat2Item,
  HoobiizCat3Id,
  HoobiizCat3Item,
  HoobiizExpertTicketInfo,
  HoobiizExpertTicketStockId,
  HoobiizExpertTicketStockItem,
  HoobiizMediaId,
  HoobiizOfferId,
  HoobiizOfferItem,
  HoobiizOrderItemFailureItem,
  HoobiizOrderItemId,
  HoobiizOrderItemItem,
  HoobiizOrderItemType,
  HoobiizOrderStatus,
  HoobiizStockId,
  HoobiizStockItem,
  HoobiizStockReservation,
  HoobiizStockWeeklyTemplateId,
  HoobiizStockWeeklyTemplateItem,
  HoobiizTicketFileId,
  HoobiizTicketFileItem,
  HoobiizTicketInfo,
  HoobiizUserGroupId,
  HoobiizUserGroupItem,
  HoobiizUserProfile,
  HoobiizVendorId,
  HoobiizVendorItem,
  StripePaymentIntentId,
  TicketHoobiizCartItemItem,
  TicketHoobiizCartItemPayload,
} from '@shared/dynamo_model';
import {Defined, None, NonEmptyArray} from '@shared/lib/type_utils';
import {ExpertTicketTransaction} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_transaction';
import {FullItem, SanitizedItem, SanitizedTicketInfo} from '@shared/model/search_tables';
import {StripeEvent} from '@shared/model/stripe';

type SearchApi = ApiDef<typeof SearchApiValue>;

export interface Promotion {
  imgSrc: string;
}

export interface Offer {
  id: string;
  imgSrc: string;
  type: string;
  description: string;
  duration: string;
  price: number;
  discountedPrice: number;
}

export interface AddressLookupResult {
  placeId: string;
  address: string;
  addressDetails: {
    streetNumber?: string;
    route?: string;
    city?: string;
    country?: string;
    postalCode?: string;
  };
  main: string;
  secondary: string;
  geometry: PlaceGeometry;
}

export interface CityLookupResult {
  description: string;
  geometry: PlaceGeometry;
}

export type HoobiizData<T> = Omit<
  T,
  'id' | 'createdAt' | 'updatedAt' | 'reserved' | 'bought' | 'remaining'
>;

export interface HoobiizCartItemTicketFull {
  type: 'ticket';
  cartItem: TicketHoobiizCartItemItem;
  ticketInfo: SanitizedTicketInfo;
  activity: SanitizedItem<'HoobiizActivity'>;
  reservations: Record<HoobiizStockId, HoobiizStockReservation>;
  offer?: SanitizedItem<'HoobiizOffer'>;
  validityDateStr: string;
}

export interface HoobiizCartItemExpertTicketFull {
  type: 'expert-ticket';
  cartItem: ExpertTicketHoobiizCartItemItem;
  ticketInfo: HoobiizExpertTicketInfo;
  activity: SanitizedItem<'HoobiizActivity'>;
  price: CurrencyAmount;
  originalPrice: CurrencyAmount;
  validityDateStr: string;
}

export type HoobiizCartItemItemFull = HoobiizCartItemTicketFull | HoobiizCartItemExpertTicketFull;

type SanitizedHoobiizOrderItemBase = Pick<
  HoobiizOrderItemItem,
  | 'id'
  | 'paymentId'
  | 'paymentClientSecret'
  | 'status'
  | 'total'
  | 'ticketInfoTotal'
  | 'ticketInfo'
  | 'quantity'
  | 'options'
  | 'fees'
> & {
  activity: SanitizedItem<'HoobiizActivity'>;
  documentUrls: string[];
  endedAt: number | undefined;
  validityDateStr: string;
};

export type SanitizedTicketHoobiizOrderItemItem = SanitizedHoobiizOrderItemBase & {
  type: HoobiizOrderItemType.Ticket;
};

export type SanitizedExpertTicketHoobiizOrderItemItem = SanitizedHoobiizOrderItemBase & {
  type: HoobiizOrderItemType.ExpertTicket;
};

export type SanitizedHoobiizOrderItemItem =
  | SanitizedTicketHoobiizOrderItemItem
  | SanitizedExpertTicketHoobiizOrderItemItem;

export type HoobiizActivitySorting =
  | {type: 'position'; lat: number; lng: number}
  | {type: 'featured'};

export interface HoobiizAdminAccounting {
  orderCount: number;
  ticketCount: number;
  cents: number;
}

export interface HoobiizGroupItem {
  parentGroupId: HoobiizUserGroupId;
  groupId: HoobiizUserGroupId;
  groupLabel: string;
  groupProfile: Defined<HoobiizUserGroupItem['groupProfile']>;
  groupDirectUserCount: Defined<HoobiizUserGroupItem['groupDirectUserCount']>;
  groupNestedUserCount: Defined<HoobiizUserGroupItem['groupNestedUserCount']>;
}

export interface HoobiizUserItem {
  parentGroupId: HoobiizUserGroupId;
  userId: FrontendUserId;
  userEmail: EmailString;
}

export const HoobiizApi: ApiMetadata<
  {
    // NEW
    '/list-cat1': {
      req: None;
      res: {cat1: SanitizedItem<'HoobiizCat1'>[]; total: number};
    };
    '/list-cat2': {
      req: {cat1Id: HoobiizCat1Id};
      res: {
        cat1: SanitizedItem<'HoobiizCat1'>;
        cat2: SanitizedItem<'HoobiizCat2'>[];
        total: number;
      };
    };
    '/list-cat3': {
      req: {cat2Id: HoobiizCat2Id};
      res: {
        cat2: SanitizedItem<'HoobiizCat2'>;
        cat3: SanitizedItem<'HoobiizCat3'>[];
        total: number;
      };
    };
    '/get-cat3': {
      req: {cat3Id: HoobiizCat3Id};
      res: {cat3: SanitizedItem<'HoobiizCat3'> | undefined};
    };
    '/list-activity': {
      req: {
        catId?: HoobiizCat1Id | HoobiizCat2Id | HoobiizCat3Id;
        vendorId?: HoobiizVendorId;
        excludeCse?: boolean;
        excludeNonCse?: boolean;
        sort: HoobiizActivitySorting;
      };
      res: {activities: SanitizedItem<'HoobiizActivity'>[]; total: number};
    };
    '/get-activity': {
      req: {activityId: HoobiizActivityId};
      res: {activity: SanitizedItem<'HoobiizActivity'> | undefined};
    };
    '/search-activity': {
      req: {
        query: string;
      };
      res: {
        activities: SanitizedItem<'HoobiizActivity'>[];
        total: number;
      };
    };
    '/search-city': {
      req: {
        query: string;
      };
      res: CityLookupResult[];
    };
    '/list-activity-stock': {
      req: {activityId: HoobiizActivityId; startTs: number; endTs: number};
      res: {
        stocks: {stock: SanitizedItem<'HoobiizStock'>; offers: SanitizedItem<'HoobiizOffer'>[]}[];
        expertTicketFlexibleStocks: HoobiizExpertTicketFlexibleStock[];
        expertTicketFixedStocks: HoobiizExpertTicketFixedStock[];
        hasFixedStocks: boolean;
        hasFlexibleStocks: boolean;
      };
    };
    '/get-vendor': {
      req: {vendorId: HoobiizVendorId};
      res: {vendor: SanitizedItem<'HoobiizVendor'> | undefined};
    };
    '/update-account': {
      req: {firstName: string; lastName: string};
      res: {userData: FrontendUserDataContentHoobiiz};
    };
    '/send-message': {
      req: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        contactType: string;
        message: string;
      };
      res: None;
    };
    // CART
    '/cart-count': {
      req: {};
      res: {count: number};
    };
    '/list-cart': {
      req: {};
      res: {
        cartItems: HoobiizCartItemItemFull[];
        pendingOrders: SanitizedHoobiizOrderItemItem[];
      };
    };
    '/add-ticket-to-cart': {
      req: TicketHoobiizCartItemPayload;
      res: {item: HoobiizCartItemItemFull};
    };
    '/add-expert-ticket-to-cart': {
      req: ExpertTicketHoobiizCartItemPayload;
      res: {item: HoobiizCartItemItemFull};
    };
    '/remove-from-cart': {
      req: {id: HoobiizCartItemId};
      res: {};
    };
    // PURCHASE
    '/start-cart-purchase': {
      req: {};
      res: {
        successItems: HoobiizCartItemItemFull[];
        failedItems: HoobiizCartItemItemFull[];
        payment?: {
          amount: CurrencyAmount;
          paymentId: StripePaymentIntentId;
          clientSecret: string;
        };
      };
    };
    '/cancel-purchase': {
      req: {paymentId: StripePaymentIntentId};
      res: {};
    };
    '/get-purchase': {
      req: {paymentId: StripePaymentIntentId};
      res: SanitizedHoobiizOrderItemItem[];
    };
    '/list-purchase': {
      req: {};
      res: SanitizedHoobiizOrderItemItem[];
    };
    '/stripe/webhook': {
      req: StripeEvent;
      res: {};
    };
    '/get-order-from-token': {
      req: {token: string};
      res: {order: SanitizedHoobiizOrderItemItem | undefined; user?: {email: string}};
    };
    '/finalize-purchase': {
      req: {token: string; orderId: HoobiizOrderItemId; action: 'confirm' | 'reject'};
      res: {order: SanitizedHoobiizOrderItemItem; user?: {email: string}};
    };
    //
    // ----------------------------------
    //               ADMIN
    // ----------------------------------
    //
    '/admin/search/query': SearchApi['/search/query'];
    '/admin/search/get': SearchApi['/search/get'];
    '/admin/address-lookup': {
      req: {
        input: string;
      };
      res: AddressLookupResult[];
    };
    //
    // Media
    //
    '/admin/start-media-upload': {
      req: {mimeType: string};
      res: {id: HoobiizMediaId; url: string};
    };
    //
    // Vendor
    //
    '/admin/create-vendor': {
      req: HoobiizData<HoobiizVendorItem>;
      res: {
        vendor: HoobiizVendorItem;
      };
    };
    '/admin/update-vendor': {
      req: {
        id: HoobiizVendorId;
        updates: HoobiizData<HoobiizVendorItem>;
      };
      res: {
        vendor: HoobiizVendorItem;
      };
    };
    '/admin/delete-vendor': {
      req: {
        id: HoobiizVendorId;
      };
      res: None;
    };
    //
    // Cat1
    //
    '/admin/create-cat1': {
      req: Omit<HoobiizData<HoobiizCat1Item>, 'order'>;
      res: {
        cat1: HoobiizCat1Item;
      };
    };
    '/admin/update-cat1': {
      req: {
        id: HoobiizCat1Id;
        updates: Partial<HoobiizData<HoobiizCat1Item>>;
      };
      res: {
        cat1: HoobiizCat1Item;
      };
    };
    '/admin/delete-cat1': {
      req: {
        id: HoobiizCat1Id;
      };
      res: None;
    };
    //
    // Cat2
    //
    '/admin/create-cat2': {
      req: Omit<HoobiizData<HoobiizCat2Item>, 'order'>;
      res: {
        cat2: HoobiizCat2Item;
      };
    };
    '/admin/update-cat2': {
      req: {
        id: HoobiizCat2Id;
        updates: Partial<HoobiizData<HoobiizCat2Item>>;
      };
      res: {
        cat2: HoobiizCat2Item;
      };
    };
    '/admin/delete-cat2': {
      req: {
        id: HoobiizCat2Id;
      };
      res: None;
    };
    //
    // Cat3
    //
    '/admin/create-cat3': {
      req: Omit<HoobiizData<HoobiizCat3Item>, 'order'>;
      res: {
        cat3: HoobiizCat3Item;
      };
    };
    '/admin/update-cat3': {
      req: {
        id: HoobiizCat3Id;
        updates: Partial<HoobiizData<HoobiizCat3Item>>;
      };
      res: {
        cat3: HoobiizCat3Item;
      };
    };
    '/admin/delete-cat3': {
      req: {
        id: HoobiizCat3Id;
      };
      res: None;
    };
    //
    // Activity
    //
    '/admin/create-activity': {
      req: HoobiizData<HoobiizActivityItem>;
      res: {
        activity: HoobiizActivityItem;
      };
    };
    '/admin/update-activity': {
      req: {
        id: HoobiizActivityId;
        updates: HoobiizData<HoobiizActivityItem>;
      };
      res: {
        activity: HoobiizActivityItem;
      };
    };
    '/admin/delete-activity': {
      req: {
        id: HoobiizActivityId;
      };
      res: None;
    };
    //
    // TicketFile
    //
    '/admin/start-ticket-file-upload': {
      req: {mimeType: string};
      res: {id: HoobiizTicketFileId; url: string};
    };
    '/admin/finish-ticket-file-upload': {
      req: {id: HoobiizTicketFileId};
      res: {fileItem: HoobiizTicketFileItem};
    };
    //
    // Stock
    //
    '/admin/create-stocks': {
      req: (HoobiizData<HoobiizStockItem> | HoobiizData<HoobiizStockWeeklyTemplateItem>)[];
      res: {};
    };
    '/admin/update-stocks': {
      req: (
        | {id: HoobiizStockId; updates: HoobiizData<HoobiizStockItem>}
        | {
            id: HoobiizStockWeeklyTemplateId;
            updates: HoobiizData<HoobiizStockWeeklyTemplateItem>;
          }
      )[];
      res: {};
    };
    '/admin/delete-stocks': {
      req: (HoobiizStockId | HoobiizStockWeeklyTemplateId)[];
      res: {};
    };
    '/admin/manage-stocks': {
      req: {
        createStock?: HoobiizData<HoobiizStockItem> | HoobiizData<HoobiizStockWeeklyTemplateItem>;
        updateStock?:
          | {id: HoobiizStockId; updates: HoobiizData<HoobiizStockItem>}
          | {
              id: HoobiizStockWeeklyTemplateId;
              updates: HoobiizData<HoobiizStockWeeklyTemplateItem>;
            };
        deleteStock?: HoobiizStockId | HoobiizStockWeeklyTemplateId;
      }[];
      res: {};
    };
    '/admin/activity-stocks': SearchApi['/search/admin-activity-stock'];
    '/admin/generate-pdf': {
      req: Omit<TicketHoobiizCartItemPayload, 'stockIds' | 'ticketInfoId'> & {
        activityId: HoobiizActivityId;
        stocks: {id: HoobiizStockId; reservation: HoobiizStockReservation}[];
        ticketInfo: HoobiizTicketInfo;
      };
      res: {url: string};
    };
    '/admin/adjust-stock': {
      req: {
        stockId: HoobiizStockId;
        delta: number;
      };
      res: {};
    };
    //
    // Expert Ticket Stock
    //
    '/admin/create-expert-ticket-stocks': {
      req: HoobiizData<HoobiizExpertTicketStockItem>[];
      res: {};
    };
    '/admin/update-expert-ticket-stocks': {
      req: {id: HoobiizExpertTicketStockId; updates: HoobiizData<HoobiizExpertTicketStockItem>}[];
      res: {};
    };
    '/admin/delete-expert-ticket-stocks': {
      req: HoobiizExpertTicketStockId[];
      res: {};
    };
    '/admin/manage-expert-ticket-stocks': {
      req: {
        createStock?: HoobiizData<HoobiizExpertTicketStockItem>;
        updateStock?: {
          id: HoobiizExpertTicketStockId;
          updates: HoobiizData<HoobiizExpertTicketStockItem>;
        };
        deleteStock?: HoobiizExpertTicketStockId;
      }[];
      res: {};
    };
    //
    // Offer
    //
    '/admin/create-offer': {
      req: HoobiizData<HoobiizOfferItem>;
      res: {
        offer: HoobiizOfferItem;
      };
    };
    '/admin/update-offer': {
      req: {
        id: HoobiizOfferId;
        updates: HoobiizData<HoobiizOfferItem>;
      };
      res: {
        offer: HoobiizOfferItem;
      };
    };
    '/admin/delete-offer': {
      req: {
        id: HoobiizOfferId;
      };
      res: None;
    };
    //
    // ExpertTicket
    //
    '/admin/expert-ticket-catalog': {
      req: {};
      res: {items: FullItem<'HoobiizExpertTicketTicket'>[]};
    };
    '/admin/expert-ticket-real-time-prices': {
      req: {
        productIds: string[];
      };
      res: ExpertTicketRealPriceResponse;
    };
    '/admin/expert-ticket-reservation': {
      req: {
        products: {
          id: string;
          quantity: number;
          accessDateTime: string;
        }[];
      };
      res: ExpertTicketReservationResponse;
    };
    '/admin/expert-ticket-create-transaction': {
      req: {
        products: {
          id: string;
          quantity: number;
          accessDateTime: string;
        }[];
        reservationId: string;
      };
      res: {
        res: ExpertTicketTransactionResponse;
        documents: string[];
      };
    };
    '/admin/expert-ticket-list-transaction': {
      req: {};
      res: {transactions: ExpertTicketTransaction[]; documents: Record<string, string[]>};
    };
    '/admin/expert-ticket-cancellation-request': {
      req: {saleId: string};
      res: {cancellationRequestId: string};
    };
    //
    // Orders
    //
    '/admin/list-orders': {
      req: {status: HoobiizOrderStatus; limit: number; nextPageToken?: string};
      res: {
        items: {
          sanitizedOrderItem: SanitizedHoobiizOrderItemItem;
          orderItem: HoobiizOrderItemItem;
          user?: FrontendUserItem;
        }[];
        nextPageToken?: string;
      };
    };
    '/admin/list-order-failures': {
      req: {limit: number; nextPageToken?: string};
      res: {
        items: {
          failures: NonEmptyArray<HoobiizOrderItemFailureItem>;
          sanitizedOrderItem: SanitizedHoobiizOrderItemItem;
          orderItem: HoobiizOrderItemItem;
          user?: FrontendUserItem;
        }[];
        nextPageToken?: string;
      };
    };
    //
    // Accounting
    //
    '/admin/accounting': {
      req: {};
      res: {
        accounting: Record<string, HoobiizAdminAccounting>;
      };
    };
    //
    // User and groups
    //
    '/admin/get-user-group': {
      req: {groupId: HoobiizUserGroupId};
      res: {item: HoobiizGroupItem};
    };
    '/admin/get-user': {
      req: {userId: FrontendUserId};
      res: {user: HoobiizUserItem; groups: HoobiizGroupItem[]};
    };
    '/admin/list-sub-groups-in-group': {
      req: {
        groupId: HoobiizUserGroupId;
        nextPageToken?: string;
      };
      res: {
        items: HoobiizGroupItem[];
        nextPageToken?: string | undefined;
      };
    };
    '/admin/list-users-in-group': {
      req: {
        groupId: HoobiizUserGroupId;
        nextPageToken?: string;
        limit?: number;
      };
      res: {
        items: HoobiizUserItem[];
        nextPageToken?: string | undefined;
      };
    };
    '/admin/create-user-group': {
      req: {
        groupLabel: string;
        parentGroupId: HoobiizUserGroupId;
      };
      res: HoobiizGroupItem;
    };
    '/admin/update-user-group': {
      req: {
        groupId: HoobiizUserGroupId;
        groupLabel?: string;
        groupProfile?: HoobiizUserProfile;
      };
      res: HoobiizGroupItem;
    };
    '/admin/delete-user-group': {
      req: {groupId: HoobiizUserGroupId};
      res: None;
    };
    '/admin/move-user-to-group': {
      req: {
        userId: FrontendUserId;
        groupIds: HoobiizUserGroupId[];
      };
      res: None;
    };
  } & FrontendSharedApi
> = {isFrontend: true, ipv6Compatible: true};
